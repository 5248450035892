import React, { useState, useRef } from "react";
import { isMobile } from "react-device-detect";
import { isIOS } from "react-device-detect";
import "./BetterLightbox.css";
import placeholderImage from "./take-photo.jpg";
import loaderImage from "./search-1.gif";

import data from './output.json';

// import JSZip from "jszip";

function ImageConverter() {
  const [images, setImages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [imagePreview, setImagePreview] = useState(placeholderImage);
  const [isImageSelected, setIsImageSelected] = useState(false);
  
  const fileInputRef = useRef(null);
  // const imagePath = "https://wcudaipur23-public.s3.ap-south-1.amazonaws.com/";
  const imagePath = "https://aipvc24.nagpur.pro/images/";

  // Function to find details based on image name
  const findImageDetails = imageName => {
    // Assuming imageName is the file name in the photo property of your JSON
    return data.find(item => item.photo === imageName);
  };

  const handleFileChange = (event) => {
    
    
    const file = event?.target?.files[0];

    
    if (file && ["image/jpeg", "image/png"].includes(file.type)) {
      setImagePreview(URL.createObjectURL(file));
      setIsImageSelected(true);
      setErrorMessage("");
    } else {
      setErrorMessage("Please select a JPG, JPEG, or PNG file.");
      resetFileInput();
    }
  };

  const resetFileInput = () => {
    setTimeout(() => {}, 300);
    setImagePreview(placeholderImage);
    setIsImageSelected(false);
    fileInputRef.current.value = "";
  };

 

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    const file = fileInputRef.current.files[0];
    if (!file) {
      setErrorMessage("Please select a file to upload.");
      setIsLoading(false);
      resetFileInput();
      return;
    }

    const reader = new FileReader();
    reader.onloadend = () => {
      const img = new Image();
      img.src = reader.result;

      img.onload = async () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0);

        const reducedQualityImage = canvas.toDataURL("image/jpeg", 0.5);

        try {
          const response = await fetch(
            "https://b51oqhl2zf.execute-api.ap-south-1.amazonaws.com/default/aipvc24-search",
            {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify({
                image_bytes: reducedQualityImage.split("base64,")[1],
              }),
              redirect: "follow",
            }
          );

          if (!response.ok) {
            throw new Error("Network response was not ok");
          }

          const data = await response.json();

          if (data && data.length > 0) {
            setImages(
              data.map((imageName) => ({
                url: imagePath + imageName,
                alt: imageName,
              }))
            );
            
          } else {
            setErrorMessage("Player Not Found, Verify Manually");
          }
        } catch (error) {
          console.error("Error:", error);
          setErrorMessage(
            "An error occurred while fetching your images. Please try again."
          );
        } finally {
          setIsLoading(false);
        }
      };
    };
    reader.readAsDataURL(file);
  };


  const buttonText = isMobile
    ? "Scan players face to find details"
    : "Upload Players face image to find details";





  return (
    <div className="container mx-auto p-6 bg-white shadow rounded-lg">
      <div className="flex items-center justify-between p-4 bg-gray-100 rounded-lg shadow transition-all duration-300 ease-in-out hover:shadow-md">
        <div className="flex flex-col">
          <p className="text-lg text-gray-800 font-semibold">{buttonText}</p>

          {!isIOS ? (
            <small className="text-gray-600">
              Click on Image below to start
            </small>
          ) : null}
        </div>
        <button
          className="flex items-center justify-center w-12 h-12 rounded-full bg-blue-200 text-blue-600 shadow hover:shadow-md hover:bg-blue-300 transition ease-in-out duration-300 focus:outline-none"
          
        >
          <span className="sr-only">Information</span>ℹ
        </button>
      </div>
      <form id="imageForm" onSubmit={handleSubmit} className="space-y-6 mt-12 block w-full text-center cursor-pointer">
        {!isIOS ? (
          <label htmlFor="myinput" style={{ cursor: "pointer" }}>
            <img
              src={imagePreview}
              alt="Preview"
              className="inline-block h-48 w-auto object-cover rounded-full border-4 border-gray-300 shadow-lg"
            />
          </label>
        ) : null }

      <input
          ref={fileInputRef}
          type="file"
          accept="image/jpeg, image/png"
          capture="user"
          onChange={handleFileChange}
          className={!isIOS?'hidden':'block w-full text-lg text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400'}
          id="myinput"
        /> 

        {isImageSelected && (
          <div className="flex justify-center">
            <button
              type="submit"
              className="bg-green-600 hover:bg-green-700 text-white font-bold py-3 px-6 rounded-lg shadow-lg transition ease-in-out duration-300 hover:scale-95 w-80"
            >
              Find Details
            </button>
          </div>
        )}
      </form>

      {isLoading && (
        <div className="flex justify-center items-center">
          <img src={loaderImage} alt="Loading..." className="w-16 h-16 m-10" />
        </div>
      )}
      {errorMessage && !isLoading && (
        <div className="p-4 mb-4 text-base text-red-800 bg-red-200 rounded-lg shadow">
          {errorMessage}
        </div>
      )}

<div id="response" className="mt-6">
        {images.map((image, index) => {
          const imageDetails = findImageDetails(image.alt); // Assuming alt attribute has the image name
          return (
            <div key={image.alt + "_" + index} className="flex mb-6">
              <div className="w-2/5">
                <img
                  src={image.url}
                  alt={image.alt}
                  className="object-cover w-full h-full rounded-lg"
                  loading="lazy"
                  onError={(e) => { e.target.src = placeholderImage; }}
                />
              </div>
              <div className="w-3/5 pl-4 flex flex-col justify-center space-y-2">
  {imageDetails ? (
    <>
      <h2 className="text-2xl font-bold text-gray-800">{imageDetails.name || 'Not available'}</h2>
      <h3 className="text-xl font-semibold text-gray-700">{imageDetails.cat}</h3>
      <div className="text-md text-gray-600">
        <p><span className="font-semibold">Unit:</span> {imageDetails.unit || 'Not available'}</p>
        <p><span className="font-semibold">Event:</span> {imageDetails.event || 'Not available'}</p>
        <p><span className="font-semibold">Mobile:</span> {imageDetails.mob || 'Not available'}</p>
        {/* Manager Information with Conditional Display */}
        <p>
          <span className="font-semibold">Manager:</span> 
          {imageDetails.manager ? `${imageDetails.manager}${imageDetails.m_num ? ` (Mob: ${imageDetails.m_num})` : ''}` : 'Not Available'}
        </p>
        {/* Additional details can be added here */}
      </div>
    </>
  ) : (
    <p className="text-gray-500">No details found for image {image.alt}</p>
  )}
</div>


            </div>
          );
        })}
      </div>

     
    </div>
  );
}

export default ImageConverter;
