import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

// Import your components here
import HomePage from './HomePage'; 
import Header from './Header'; 
import Footer from './Footer'; 

function App() {
  return (
    <Router>
      <div className="min-h-screen bg-gray-100 text-gray-900 flex flex-col">
        <Header />

        <Switch>
          <Route path="/" exact component={HomePage} />
          
        </Switch>

        <Footer />
      </div>
    </Router>
  );
}

export default App;
